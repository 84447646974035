<template>
  <section class="flex items-center justify-center">
    <div
      class="
        aspect-[16/9]
        bg-white
        container
        flex flex-col
        m-20
        rounded-2xl
        shadow-2xl shadow-WALIGHTBLUE-200
        w-[800px]
        md:flex-row
      "
    >
      <div class="pl-8 px-8 py-4 w-full">
        <img src="/login-pic.svg" class="h-full" />
      </div>
      <div class="flex flex-col items-center justify-center p-8 w-full">
        <div class="mb-14">
          <img src="/wa-logo.png" class="w-48" />
        </div>
        <div class="mb-4">
          <h4
            class="font-bold text-WADARKBLUE-500 text-center text-xs uppercase"
          >
            {{ $t("reset-password-title") }}
          </h4>
        </div>
        <form class="flex flex-col items-center" @submit.prevent="onSubmit">
          <div class="mb-4 relative">
            <input
              :placeholder="$('new-password')"
              v-model="newPassword"
              class="
                border-b
                focus:bg-gray-50 focus:border-WAORANGE-500
                outline-none
                p-2
                pl-10
                text-sm
                w-64
              "
            />
            <div
              class="
                absolute
                flex
                inset-y-0
                items-center
                left-0
                pointer-events-none
                px-2
              "
            >
              <img src="/icon-login-password.svg" class="w-3" />
            </div>
          </div>
          <div class="mb-4 relative">
            <input
              :placeholder="$('repeat-new-password')"
              v-model="confirmPassword"
              class="input-field outline-none p-2 pl-10 text-sm w-64"
            />
            <div
              class="
                absolute
                flex
                inset-y-0
                items-center
                left-0
                pointer-events-none
                px-2
              "
            >
              <img src="/icon-login-password.svg" class="w-3" />
            </div>
          </div>
          <div class="mt-2">
            <button
              class="
                bg-WADARKBLUE-500
                hover:bg-WADARKBLUE-400
                p-3
                rounded-full
                text-sm text-white
                w-64
                uppercase
              "
            >
              {{ $("reset") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import httpClient from "../services/httpClient";
import { mapMutations } from "vuex";
import { accessTokenKey, refreshTokenKey } from "../services/authServcies";

export default {
  data() {
    return {
      user: {},
      resettoken: null,
      newPassword: "",
      confirmPassword: "",
      error: null,
      loading: false,
    };
  },
  methods: {
    ...mapMutations(["setAccessToken", "setRefreshToken", "setCurrentUser"]),
    async verifyResetToken() {
      try {
        const { data } = await httpClient.get(
          `/auth/password/verify/${this.resettoken}`
        );
        this.user = data;
      } catch (err) {
        this.$router.replace("/login");
      }
    },
    async onSubmit() {
      this.error = null;

      if (this.confirmPassword !== this.newPassword)
        return (this.error = "Password do not match");

      try {
        const { data } = await httpClient.post(
          `/auth/resetpassword/${this.resettoken}`,
          { password: this.newPassword }
        );
        this.setAccessToken(data[accessTokenKey]);
        this.setRefreshToken(data[refreshTokenKey]);
        this.setCurrentUser(data.user);
        localStorage.setItem(accessTokenKey, data[accessTokenKey]);
        localStorage.setItem(refreshTokenKey, data[refreshTokenKey]);
        this.$router.replace("/");
      } catch (err) {
        console.error(err);
      }
    },
  },

  created() {
    this.resettoken = this.$route.params.resettoken;
    this.verifyResetToken();
  },
};
</script>

<style scoped>
section {
  min-height: 85vh;
}
</style>
